import { WrapperAnchorStoryblok } from 'lib/storyblok/types'

import { DynamicBlock } from './DynamicBlock'

interface WrapperAnchorProps {
  block: WrapperAnchorStoryblok
}

export const WrapperAnchor: React.FC<WrapperAnchorProps> = ({
  block,
  ...props
}) => {
  const { id, body } = block

  return (
    <div id={id} {...props}>
      {(body || []).map((block) => (
        <DynamicBlock key={block._uid} block={block} />
      ))}
    </div>
  )
}
